import React from "react";
import { Link } from 'react-router-dom';

const Breadcrumb = ({links}) => {
    return(
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {(() => {
                    let htmlArray = [];
                    for(let i = 0; i < links.length; i++){
                        if(links[i].route == "#"){
                            htmlArray.push(<li key={i} className="breadcrumb-item active"><a aria-current="page">{links[i].text}</a></li>);
                        }else{
                            htmlArray.push(<li key={i} className="breadcrumb-item"><Link to={links[i].route}>{links[i].text}</Link></li>);
                        }
                    }
                    return (htmlArray);
                })()}
            </ol>
        </nav>
    );
}

export default Breadcrumb;
import "../css/users.css";

import React from "react";
import Breadcrumb from "../components/breadcrumb";
import ModalForm from "../components/modalForm";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Permissions = () => {  

    let breadcrumbItens = [
        {
            text: "Home",
            route: "/"
        },
        {
            text: "Permissões",
            route: "#"
        }
    ];

    let inputs = [
            {              
                type: "text",
                name: "name",
                label: "Nome",
                isRequired: true
            },   
            {
                type: "checkbox",
                name: "tbl_users",
                label: "Usuários",
                value:"1",
                isRequired: false,
                options:[
                            {
                                text: "Ler",
                                value: "1",
                                checked: false
                            },
                            {
                                text: "Escrever",
                                value: "2",
                                checked: false
                            },
                            {
                                text: "Apagar",
                                value: "3",
                                checked: false
                            }
                        ]
            },
            {
                type: "checkbox",
                name: "tbl_permissions",
                label: "Permissões",
                value:"1",
                isRequired: false,
                options:[
                            {
                                text: "Ler",
                                value: "1",
                                checked: false
                            },
                            {
                                text: "Escrever",
                                value: "2",
                                checked: false
                            },
                            {
                                text: "Apagar",
                                value: "3",
                                checked: false
                            }
                        ]
            },
            {
                type: "checkbox",
                name: "tbl_verticals",
                label: "Verticais",
                value:"1",
                isRequired: false,
                options:[
                            {
                                text: "Ler",
                                value: "1",
                                checked: false
                            },
                            {
                                text: "Escrever",
                                value: "2",
                                checked: false
                            },
                            {
                                text: "Apagar",
                                value: "3",
                                checked: false
                            }
                        ]
            },
            {
                type: "checkbox",
                name: "tbl_key_values",
                label: "Key Values",
                value:"1",
                isRequired: false,
                options:[
                            {
                                text: "Ler",
                                value: "1",
                                checked: false
                            },
                            {
                                text: "Escrever",
                                value: "2",
                                checked: false
                            },
                            {
                                text: "Apagar",
                                value: "3",
                                checked: false
                            }
                        ]
            },
            {
                type: "checkbox",
                name: "tbl_google_ads",
                label: "Google Ads",
                value:"1",
                isRequired: false,
                options:[
                            {
                                text: "Ler",
                                value: "1",
                                checked: false
                            },
                            {
                                text: "Escrever",
                                value: "2",
                                checked: false
                            },
                            {
                                text: "Apagar",
                                value: "3",
                                checked: false
                            }
                        ]
            },
            {
                type: "checkbox",
                name: "tbl_facebook",
                label: "Meta Marketing",
                value:"1",
                isRequired: false,
                options:[
                            {
                                text: "Ler",
                                value: "1",
                                checked: false
                            },
                            {
                                text: "Escrever",
                                value: "2",
                                checked: false
                            },
                            {
                                text: "Apagar",
                                value: "3",
                                checked: false
                            }
                        ]
            },
            {
                type: "checkbox",
                name: "tbl_active",
                label: "Active Campaign",
                value:"1",
                isRequired: false,
                options:[
                            {
                                text: "Ler",
                                value: "1",
                                checked: false
                            },
                            {
                                text: "Escrever",
                                value: "2",
                                checked: false
                            },
                            {
                                text: "Apagar",
                                value: "3",
                                checked: false
                            }
                        ]
            },
    ];

    return (
        <div className="container">
            <ModalForm input={inputs} action="save-permission" elemId="permissionModal" isEdit={false} title="Permissão" />
            <ModalForm input={inputs} action="update-permission" elemId="editPermissionModal" isEdit={true} title="Permissão" />

            <div className="defaultContainer">
               
                <div className="row">
                    <div className="col-12">
                        <h1 className="h1">Permissões
                            <button type="button" className="btn btn-sm btn-add" data-bs-toggle="modal" data-bs-target="#permissionModal">
                                <span className="material-symbols-outlined transition">add_box</span>
                            </button>
                        </h1>
                        <Breadcrumb links={breadcrumbItens} />
                    </div>
                </div>
                <br />
                
                <div className="row">
                    <div className="col-12">
                        <table id="dtPermissions" className="dataTables cell-border table table-bordered table-striped" style={{width:"100%"}}>
                            <thead>
                                <tr className="thead-dark">
                                    <th>Nome</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                            <tfoot></tfoot>
                        </table>    
                    </div>
                </div> 

            </div>
            <HelmetProvider>
                <Helmet>
                    <script src="./js/checkPermissions.js" type="text/javascript"></script>
                    <script src="./js/datatables/permissions.js" type="text/javascript"></script>
                </Helmet>
            </HelmetProvider> 
        </div>
    );
}

export default Permissions;
import "../css/users.css";

import React, {useState, useEffect} from "react";
import Breadcrumb from "../components/breadcrumb";
import ModalForm from "../components/modalForm";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import apiUrl from '../globals';

const Users = () => {  

    const [permissions, setPermissions] = useState([]);

    const getPermissions = async () => {
        try {
            const response = await fetch(apiUrl+'/get-permissions-data', {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Accept": "*/*",
                                            //"token" : apiToken
                                        } 
                                    });                   
            const json = await response.json();
            let optionsArray = [];

            for (let i = 0; i < json.length; i++) {
                optionsArray.push({text: json[i].name, value: json[i].id});                
            }

            //console.log(optionsArray);
            setPermissions(optionsArray);

        }catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
        getPermissions();
    }, []);

    let breadcrumbItens = [
        {
            text: "Home",
            route: "/"
        },
        {
            text: "Usuários",
            route: "#"
        }
    ];

    let inputs = [
            {              
                type: "text",
                name: "name",
                label: "Nome",
                isRequired: true
            },
            {
                type: "email",
                name: "email",
                label: "E-mail",
                isRequired: true
            }, 
            {
                type: "password",
                name: "password",
                label: "Senha",
                isRequired: true
            },   
            {
                type: "select",
                name: "user_permission",
                label: "Permissão",
                isRequired: false,
                id: "user_permission",
                value :"1",
                options: permissions
            },
            // {
            //     type: "radio",
            //     name: "user_prefer1",
            //     label: "Preferencia usuário",
            //     value:"3",
            //     isRequired: false,
            //     options:[
            //                 {
            //                     text: "Opção 1",
            //                     value: "1",
            //                     checked: false
            //                 },
            //                 {
            //                     text: "Opção 2",
            //                     value: "2",
            //                     checked: false
            //                 },
            //                 {
            //                     text: "Opção 3",
            //                     value: "3",
            //                     checked: true
            //                 }
            //             ]
            // },
            // {
            //     type: "checkbox",
            //     name: "user_prefer1",
            //     label: "Preferencia usuário",
            //     value:"3",
            //     isRequired: false,
            //     options:[
            //                 {
            //                     text: "Opção 1",
            //                     value: "1",
            //                     checked: false
            //                 },
            //                 {
            //                     text: "Opção 2",
            //                     value: "2",
            //                     checked: false
            //                 },
            //                 {
            //                     text: "Opção 3",
            //                     value: "3",
            //                     checked: true
            //                 }
            //             ]
            // },               
            // {
            //     type: "textarea",
            //     name: "description",
            //     label: "Descrição",
            //     isRequired: false,
            // },

    ];

    return (
        <div className="container">
            <ModalForm input={inputs} action="save-user" elemId="userModal" isEdit={false} title="Usuário" />
            <ModalForm input={inputs} action="update-user" elemId="editUserModal" isEdit={true} title="Usuário" />

            <div className="defaultContainer">
               
                <div className="row">
                    <div className="col-12">
                        <h1 className="h1">Usuários
                            <button type="button" className="btn btn-sm btn-add" data-bs-toggle="modal" data-bs-target="#userModal">
                                <span className="material-symbols-outlined transition">add_box</span>
                            </button>
                        </h1>
                        <Breadcrumb links={breadcrumbItens} />
                    </div>
                </div>
                <br />
                
                <div className="row">
                    <div className="col-12">
                        <table id="dtUsers" className="dataTables cell-border table table-bordered table-striped" style={{width:"100%"}}>
                            <thead>
                                <tr className="thead-dark">
                                    <th>Nome</th>
                                    <th>E-mail</th>
                                    <th>Permissão</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                            <tfoot></tfoot>
                        </table>    
                    </div>
                </div> 

            </div>
            <HelmetProvider>
                <Helmet>
                    <script src="./js/checkPermissions.js" type="text/javascript"></script>
                    <script src="./js/datatables/users.js" type="text/javascript"></script>
                </Helmet>
            </HelmetProvider> 
        </div>
    );
}

export default Users;
import "../css/users.css";

import React from "react";
import Breadcrumb from "../components/breadcrumb";
import ModalForm from "../components/modalForm";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Active = () => {  

    let breadcrumbItens = [
        {
            text: "Home",
            route: "/"
        },
        {
            text: "Active campaign",
            route: "#"
        }
    ];

    let inputs = [
            {              
                type: "text",
                name: "name",
                label: "Nome",
                isRequired: true
            },
            {
                type: "text",
                name: "description",
                label: "Descrição",
                isRequired: false
            },
            {
                type: "text",
                name: "api_url",
                label: "Api Url",
                isRequired: false
            },
            {
                type: "text",
                name: "token",
                label: "Token",
                isRequired: false
            } 
    ];

    return (
        <div className="container">
            <ModalForm input={inputs} action="save-active" elemId="activeModal" isEdit={false} title="Active campaign" />
            <ModalForm input={inputs} action="update-active" elemId="editActiveModal" isEdit={true} title="Active campaign" />

            <div className="defaultContainer">
               
                <div className="row">
                    <div className="col-12">
                        <h1 className="h1">Active campaign
                            <button type="button" className="btn btn-sm btn-add" data-bs-toggle="modal" data-bs-target="#activeModal">
                                <span className="material-symbols-outlined transition">add_box</span>
                            </button>
                        </h1>
                        <Breadcrumb links={breadcrumbItens} />
                    </div>
                </div>
                <br />
                
                <div className="row">
                    <div className="col-12">
                        <table id="dtActive" className="dataTables cell-border table table-bordered table-striped" style={{width:"100%"}}>
                            <thead>
                                <tr className="thead-dark">
                                    <th>Nome</th>
                                    <th>Descrição</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                            <tfoot></tfoot>
                        </table>    
                    </div>
                </div> 

            </div>
            <HelmetProvider>
                <Helmet>
                    <script src="./js/checkPermissions.js" type="text/javascript"></script>
                    <script src="./js/datatables/active.js" type="text/javascript"></script>
                </Helmet>
            </HelmetProvider>    
        </div>
    );
}

export default Active;
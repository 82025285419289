import "../css/home.css";

import React, {useState, useEffect, useRef} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import parseHtml from 'html-react-parser';
// import ActiveLogo from "../img/active.png";
// import MetaLogo from "../img/meta.png";
// import GAMLogo from "../img/gam.png";
// import GoogleAdsLogo from "../img/google-ads.png";

const Home = () => {

    const initialized = useRef(false)

    const logout = () => {
        var Cookies = document.cookie.split(';');
         for (var i = 0; i < Cookies.length; i++){
            document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
         }
         window.location.href = "/";
    }

    const getPexelsPicture = async () => {
        
        const pexelsApiUrl = 'https://api.pexels.com/v1/search?query';
        const pexelsToken = 'lnI523rj6EUgEijLKoHFRtImGaNdTRQQt0wOpQQqhfi0uMXgV8luOWnG';
        //https://www.pexels.com/api/documentation/#photos-search__parameters__color

        const search = encodeURI("business, marketing");
        const colors = ['red', 'orange', 'yellow', 'green', 'turquoise', 'blue', 'violet', 'pink', 'brown', 'black', 'gray', 'white'];
        const randomColor = colors[Math.floor(Math.random()*colors.length)];
        const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
        const randomPage = pages[Math.floor(Math.random()*pages.length)];
    
        try {
            const response = await fetch(pexelsApiUrl+'='+search+'&per_page=1&color='+randomColor+"&page="+randomPage+"&size=large", {
                                        method: "GET",
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Accept": "*/*",
                                            "Authorization" : pexelsToken
                                        } 
                                    });                   
            const json = await response.json();
            //console.log(json.photos[0].src.large);
            window.$('#homeBGImage').css('background-image', 'url("'+json.photos[0].src.large+'")');
        }catch(error){
            console.log(error);
        }        
    }

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true;
            getPexelsPicture();
        }   
    }, []);

    return (
        <div className="container-fluid">
            <div id="homeBGImage"></div>
            <div id="homeContainer">

                <h2 className="subtitle" id="cadastrosHome">Cadastros</h2>
                <br />

                <div className="row">
                    
                    <div className="col-lg-2 col-md-2 col-sm-3" id="usuariosHome">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/usuarios">
                                    <span className="material-symbols-outlined transition">group</span>
                                    <br />
                                    <label className="transition">Usuários</label>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3"  id="permissoesHome">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/permissions">
                                    <span className="material-symbols-outlined transition">admin_panel_settings</span>
                                    <br />
                                    <label className="transition">Permissões</label>
                                </a>
                            </div>
                        </div>
                    </div> 
                    <div className="col-lg-2 col-md-2 col-sm-3"  id="verticaisHome">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/verticais">
                                    <span className="material-symbols-outlined transition">vertical_align_top</span>
                                    <br />
                                    <label className="transition">Verticais</label>
                                </a>
                            </div>
                        </div>
                    </div> 
                </div>

                <br /><br />
                <div className="row">
                    <div className="col">
                        <h2 className="subtitle" id="aquisicaoHome">Aquisição</h2>
                    </div>
                </div>
                <br /> 

                <div className="row">

                    <div className="col-lg-2 col-md-2 col-sm-3" id="googleadsHome"> 
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/google-ads">
                                    <span className="material-symbols-outlined transition">ads_click</span>
                                    <br />
                                    <label className="transition">Google Ads</label>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3" id="facebookHome">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/facebook">
                                    <span className="material-symbols-outlined transition">team_dashboard</span>
                                    <br />
                                    <label className="transition">Facebook</label>
                                </a>
                            </div>
                        </div>
                    </div> 
                    <div className="col-lg-2 col-md-2 col-sm-3"  id="keyvaluesHome">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/key-values">
                                    <span className="material-symbols-outlined transition">key</span>
                                    <br />
                                    <label className="transition">Key Values</label>
                                </a>
                            </div>
                        </div>
                    </div> 
                    <div className="col-lg-2 col-md-2 col-sm-3"  id="keyvaluesHome">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/utm-sources">
                                    <span className="material-symbols-outlined transition">swap_horiz</span>
                                    <br />
                                    <label className="transition">UTM Sources</label>
                                </a>
                            </div>
                        </div>
                    </div>                        
                </div>   


                {/*
                <br /><br />
                <div className="row">
                    <div className="col">
                        <h2 className="subtitle" id="retencaoHome">Retenção</h2>
                    </div>
                </div>
                <br /> 

                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-3">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/active">
                                    <span className="material-symbols-outlined transition">send</span>
                                    <br />
                                    <label className="transition">Active</label>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3" id="activeReportHome">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/relatorios/active">
                                    <span className="material-symbols-outlined transition">query_stats</span>
                                    <br />
                                    <label className="transition">Relatório Active</label>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                */}

                <br /><br />
                <div className="row">
                    <div className="col">
                        <h2 className="subtitle" id="relatoriosHome">Relatórios</h2>
                    </div>
                </div>
                <br /> 

                <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-3" id="roiHome">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/relatorios/roi">
                                    <span className="material-symbols-outlined transition">bar_chart</span>
                                    <br />
                                    <label className="transition">Relatório ROI</label>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3" id="roiHome2">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/relatorios/roi-campaign">
                                    <span className="material-symbols-outlined transition">campaign</span>
                                    <br />
                                    <label className="transition">ROI campanha</label>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-2 col-sm-3" id="roiHome3">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/relatorios/roi-facebook">
                                    <span className="material-symbols-outlined transition">bar_chart</span>
                                    <br />
                                    <label className="transition">ROI Facebook</label>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-2 col-sm-3" id="roiHome4">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/relatorios/roi-campaign-facebook">
                                    <span className="material-symbols-outlined transition">campaign</span>
                                    <br />
                                    <label className="transition">ROI campanha Facebook</label>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3" id="roiHome5">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/relatorios/placement">
                                    <span className="material-symbols-outlined transition">link</span>
                                    <br />
                                    <label className="transition">ROI Placement</label>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3" id="roiHome6">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/relatorios/impressoes">
                                    <span className="material-symbols-outlined transition">ad_units</span>
                                    <br />
                                    <label className="transition">Índice de impressões</label>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <br /><br />
                <h2 className="subtitle">Outros</h2>
                <br />

                <div className="row">
                    
                    <div className="col-lg-2 col-md-2 col-sm-3">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a href="/account">
                                    <span className="material-symbols-outlined transition">manage_accounts</span>
                                    <br />
                                    <label className="transition">Minha conta</label>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-2 col-sm-3">
                        <div className="card mb-3 homeCard transition">
                            <div className="card-body transition homeCardBody">
                                <a onClick={logout}>
                                    <span className="material-symbols-outlined transition">logout</span>
                                    <br />
                                    <label className="transition">Logout</label>
                                </a>
                            </div>
                        </div>
                    </div>

                </div> 

                <br /><br /><br /><br />   

            </div>

            {/* <div id="homeChartActive" className="floatingChart">

                <div className="card mb-3 reportCard">
                    <div className="card-header"><span className="material-symbols-outlined">send</span>&nbsp;<label>Leads novo do dia&nbsp;<strong>17/10/2023</strong></label></div>
                    <div className="card-body">
                        
                    </div>
                </div>

            </div> */}

            <HelmetProvider>
                <Helmet>
                    <script src="../js/home.js" type="text/javascript"></script>
                </Helmet>
            </HelmetProvider> 

        </div>
    );
}

export default Home;
import "../css/home.css";

import React, {useState, useEffect} from "react";
import parseHtml from 'html-react-parser';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Chart from 'chart.js/auto'

import apiUrl from '../globals';
import roiLogo from "../img/roi.png";

const getDateAndTime = (format_br) => {
    let currentdate = new Date(); 
    let date;

    if(typeof format_br == "undefined" || format_br == null || format_br == ""){
        date =  currentdate.getFullYear()+"-"
				+(currentdate.getMonth()+1).toString().padStart(2, "0")+"-" 
				+currentdate.getDate().toString().padStart(2, "0");    
    }else{
        date =  currentdate.getDate().toString().padStart(2, "0")+"/"
                +(currentdate.getMonth()+1).toString().padStart(2, "0")+"/" 
                +currentdate.getFullYear();    
    }

	let time = currentdate.getHours()+":"+currentdate.getMinutes()+":"+currentdate.getSeconds();
    return {"date":date, "time": time};
}

let currentdate = new Date(); 
let currentHours = ('0'+currentdate.getHours()).substr(-2);
let currentHoursMinus2 = currentHours-2;
let currentMinutes = currentdate.getMinutes();

if(currentHoursMinus2 < 10){
    currentHoursMinus2 = '0'+currentHoursMinus2;
}

if(currentMinutes < 10){
    currentMinutes = '0'+currentMinutes;
}

let timeNowVar = currentHours+":"+currentMinutes;
let timeAgoVar = currentHoursMinus2+":"+currentMinutes;

const ReportsROICampaign = () => {

    // const [timeNow, setTimeNow] = useState(timeNowVar);
    // const [timeAgo, setTimeAgo] = useState(timeAgoVar);
    const [googleAdsAccount, setGoogleAdsAccount] = useState('');
    const [actualDate, setActualDate] = useState(getDateAndTime());
    const [startDate, setStartDate] = useState(actualDate.date);
    const [endDate, setEndDate] = useState(actualDate.date);
    const [campaignName, setCampaignName] = useState('');

    Array.prototype.random = function () {
        return this[Math.floor((Math.random()*this.length))];
    }

    const getReportRoiData = async (campaignName) => {

        let retornoReportsROI = {};
        console.log("campaignName getReportRoiData: "+campaignName);

        if(campaignName !== ""){
            try {
                const response = await fetch(apiUrl+'/get-report-roi-campaign', {
                                            method: "POST",
                                            body: JSON.stringify({'campaign_name': campaignName, 'start_date': startDate, 'end_date': endDate}),
                                            headers: {
                                                "Content-Type": "application/json",
                                                "Accept": "*/*",
                                                //"token" : apiToken
                                            } 
                                        });                   
                retornoReportsROI = await response.json();
    
                console.log(retornoReportsROI)
                                                    
            }catch(error){
                console.error(error);
            }finally{
    
                //const colorsArray = ['#37306B', '#66347F', '#9E4784', '#D27685', '#F15A59', '#D5CEA3'];
                let rOIChartData = [];
                let eCPMChartData = [];
    
                for(let i = 0; i < retornoReportsROI.length; i++) {  
                    rOIChartData.push({"created_at_date":retornoReportsROI[i].created_at_date, "roi":retornoReportsROI[i].roi_percent});
                    eCPMChartData.push({"created_at_date":retornoReportsROI[i].created_at_date, "ecpm":retornoReportsROI[i].ecpm.split("$").pop()});
                }
    
                /********************************************************ROI CHART*****************************************************************/
    
                (async function() {
    
                    let chartStatus = Chart.getChart('reportROIChart');
                    if (chartStatus != undefined) {
                        chartStatus.destroy();
                    }
                
                    new Chart(
                    document.getElementById('reportROIChart'),
                    {
                        type: 'bar',
                        data: {
                        labels: rOIChartData.map(row => row.created_at_date),
                        datasets: [
                            {
                            label: 'ROI % POR DIA',
                            data: rOIChartData.map(row => row.roi),
                            //   borderColor: colorsArray,
                            //   backgroundColor: colorsArray
                            },
                        ]
                        },
                        options: {
                            scales: {
                            y: {
                                beginAtZero: true
                            }
                            }
                        },
                    }
                    );
                })();
    
                /********************************************************ECPM CHART*****************************************************************/
    
                (async function() {
    
                    //console.log(eCPMChartData);
    
                    let chartStatus = Chart.getChart('reportECPMChart');
                    if (chartStatus != undefined) {
                        chartStatus.destroy();
                    }
                
                    new Chart(
                    document.getElementById('reportECPMChart'),
                    {
                        type: 'bar',
                        data: {
                        labels: eCPMChartData.map(row => row.created_at_date),
                        datasets: [
                            {
                            label: 'ECPM POR DIA',
                            data: eCPMChartData.map(row => row.ecpm),
                            //   borderColor: colorsArray,
                                backgroundColor: "#D27685"
                            },
                        ]
                        },
                        options: {
                            scales: {
                            y: {
                                beginAtZero: true
                            }
                            }
                        },
                    }
                    );
                })();
    
                document.getElementById( 'chartsTitle' ).style.display = 'block';
    
                return retornoReportsROI;                
            }
        }
        
    }

    const clickHandlerReportROI = (event) => {
        //getReportRoiData(googleAdsAccount);   
        getReportRoiData(campaignName);     
    }

    const changeHandlerReportROI = (event) =>{
        //console.log(event.target.value);
        setGoogleAdsAccount(event.target.value);
        event.preventDefault();
    }

    const changeHandlerCampaignName = (event) =>{
        //console.log(event.target.value);
        setCampaignName(event.target.value);
        event.preventDefault();
    }

    const changeHandlerStartDate = (event) =>{
        setStartDate(event.target.value);
        event.preventDefault();
    }

    const changeHandlerEndDate = (event) =>{
        setEndDate(event.target.value);
        event.preventDefault();
    }   

    useEffect(()=>{
        //getReportRoiData();
    }, []);

    let cardTitleROI = parseHtml('<img class="cardLogoTitle" src="'+roiLogo+'"/>&nbsp;<h5 class="card-title">ROI por campanha</h5>');                       

    return (
        <div className="container-fluid">
            <div id="homeContainer">
                <div className="row">
                    <div className="col-12">
                        <div className="card border-secondary mb-3">
                            <div className="card-header d-flex justify-content-start">
                                {cardTitleROI}
                                <div className="tableLoader" id="roiLoader">
                                    <svg className="spinner" width="32px" height="32px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </div>
                            </div>
                            <div className="card-body">
                                
                                <div className="row">
                                    <div className="col-4">
                                        <div className="mb-3">
                                            <label htmlFor="filter_vertical_roi" className="form-label">Vertical</label>
                                            <select id="filter_vertical_roi" name="filter_vertical_roi" className="selectInput form-control">
                                                <option value="0">Selecione a vertical</option>     
                                            </select>    
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="mb-3">
                                            <label htmlFor="filter_account_roi" className="form-label">Conta</label>
                                            <select id="filter_account_roi" name="filter_account_roi" className="selectInput form-control" onChange={changeHandlerReportROI}>
                                                <option value="0">Selecione a vertical primeiro</option>     
                                            </select>
                                        </div>    
                                    </div>
                                    <div className="col-4">
                                        <div className="mb-3">
                                            <label htmlFor="filter_campaign_roi" className="form-label">Campanha</label>
                                            <select id="filter_campaign_roi" name="filter_campaign_roi" className="selectInput form-control" onChange={changeHandlerCampaignName}>
                                                <option value="0">Selecione a conta primeiro</option>     
                                            </select>
                                        </div>    
                                    </div>
                                </div>
                                <div className="row" style={{marginTop:"30px"}}>
                                    <div className="col">
                                        <h6>Exibindo dados de: <strong id="filterText_roi" className="text-success"></strong></h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        <div className="mb-3">
                                            <label htmlFor="start_date_roi" className="form-label">Data início</label>
                                            <input name="start_date_roi" className="form-control" id="start_date_roi" type="date" defaultValue={actualDate.date} onChange={changeHandlerStartDate} />
                                        </div>    
                                    </div>
                                    <div className="col-5">
                                        <div className="mb-3">
                                            <label htmlFor="end_date_roi" className="form-label">Data fim</label>
                                            <input name="end_date_roi" className="form-control" id="end_date_roi" type="date" defaultValue={actualDate.date} onChange={changeHandlerEndDate} />
                                        </div>    
                                    </div>
                                    <div className="col-2">
                                        <div className="mb-3">
                                            <button type="button" id="searchROICampaign" className="btn btn-outline-primary" onClick={clickHandlerReportROI}>
                                                <span className="material-symbols-outlined">filter_alt</span>&nbsp;Filtrar
                                            </button>
                                        </div>    
                                    </div>
                                </div>

                                <br /><hr />
                                {/* <br />
                                <label className="subtitle">Horas faltantes: <strong id="missing_hours">-</strong></label> */}
                                <br /><br />
                                <div className="row">
                                    <div className="col-12">
                                        <table id="dtReportsROICampaign" className="dataTables cell-border table table-bordered table-striped" style={{width:"100%"}}>
                                            <thead>
                                                <tr className="thead-dark">                                                           
                                                    <th>Conta</th>
                                                    <th>Campanha</th>
                                                    <th>Custo</th>
                                                    <th>Detalhamento/H</th>
                                                    <th>Retorno $</th>
                                                    <th>Retorno R$ </th>
                                                    <th>ROI %</th>
                                                    <th>ROI R$</th>
                                                    <th>Custo por Conversão</th>
                                                    <th>Conversões</th>
                                                    <th>eCPM</th>
                                                    <th>Hora GAM</th>
                                                    <th>Data Leitura</th>
                                                    <th>Hora Leitura</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                            <tfoot></tfoot>
                                        </table>    
                                    </div>
                                </div>

                                <br /><hr /><br />
                                <h2 className="subtitle">Totais</h2>
                                <br />

                                <div className="row">
                                    <div className="col-3">
                                        <div className="card mb-3 reportCard">
                                            <div className="card-header"><span className="material-symbols-outlined">payments</span>&nbsp;<label>Retorno em Reais</label></div>
                                            <div className="card-body">
                                                <h5 className="card-title activeTotals" id="totalRoiReais">R$0.00</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="card mb-3 reportCard">
                                            <div className="card-header"><span className="material-symbols-outlined">local_atm</span>&nbsp;<label>Retorno em Dólares</label></div>
                                            <div className="card-body">
                                                <h5 className="card-title activeTotals" id="totalRoiDollars">$0.00</h5>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="col-2">
                                        <div className="card mb-3 reportCard">
                                            <div className="card-header"><span className="material-symbols-outlined">shopping_cart</span>&nbsp;<label>Custos</label></div>
                                            <div className="card-body">
                                                <h5 className="card-title activeTotals" id="totalCustos">R$0.00</h5>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="col-2">
                                        <div className="card mb-3 reportCard">
                                            <div className="card-header"><span className="material-symbols-outlined">trending_up</span>&nbsp;<label>Percentual</label></div>
                                            <div className="card-body">
                                                <h5 className="card-title activeTotals" id="totalRoiPercent">0%</h5>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="col-2">
                                        <div className="card mb-3 reportCard">
                                            <div className="card-header"><span className="material-symbols-outlined">browse_activity</span>&nbsp;<label>Conversões</label></div>
                                            <div className="card-body">
                                                <h5 className="card-title activeTotals" id="totalRoiConvertions">0</h5>
                                            </div>
                                        </div>
                                    </div>    
                                </div>

                                <br /><hr /><br />
                                <h2 className="subtitle" id="chartsTitle">Gráficos</h2>
                                <br />

                                <div className="row">
                                    <div className="col-6 chartContainer">
                                        <canvas className="chartsHome" id="reportROIChart"></canvas>
                                    </div>
                                    <div className="col-6 chartContainer">
                                        <canvas className="chartsHome" id="reportECPMChart"></canvas>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>           
                </div> 
            </div>
            <HelmetProvider>
                <Helmet>
                    <script src="../js/datatables/reports/roi-campaign.js" type="text/javascript"></script>
                </Helmet>
            </HelmetProvider>  
        </div>
    );
}

export default ReportsROICampaign;
import "../css/users.css";

import React from "react";
import Breadcrumb from "../components/breadcrumb";
import ModalForm from "../components/modalForm";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Facebook = () => {  

    let breadcrumbItens = [
        {
            text: "Home",
            route: "/"
        },
        {
            text: "Facebook",
            route: "#"
        }
    ];

    let inputs = [
            {              
                type: "text",
                name: "name",
                label: "Nome",
                isRequired: true
            },
            {
                type: "text",
                name: "description",
                label: "Descrição",
                isRequired: false
            },
            {
                type: "text",
                name: "pixel_id",
                label: "Pixel ID",
                isRequired: true
            },
            // {
            //     type: "text",
            //     name: "access_token",
            //     label: "Access Token",
            //     isRequired: true
            // },
            {
                type: "text",
                name: "account_id",
                label: "Account ID",
                isRequired: true
            }
    ];

    return (
        <div className="container">
            <ModalForm input={inputs} action="save-facebook" elemId="facebookModal" isEdit={false} title="Facebook" />
            <ModalForm input={inputs} action="update-facebook" elemId="editFacebookModal" isEdit={true} title="Facebook" />

            <div className="defaultContainer">
               
                <div className="row">
                    <div className="col-12">
                        <h1 className="h1">Facebook
                            <button type="button" className="btn btn-sm btn-add" data-bs-toggle="modal" data-bs-target="#facebookModal">
                                <span className="material-symbols-outlined transition">add_box</span>
                            </button>
                        </h1>
                        <Breadcrumb links={breadcrumbItens} />
                    </div>
                </div>
                <br />
                
                <div className="row">
                    <div className="col-12">
                        <table id="dtFacebook" className="dataTables cell-border table table-bordered table-striped" style={{width:"100%"}}>
                            <thead>
                                <tr className="thead-dark">
                                    <th>Nome</th>
                                    <th>Descrição</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                            <tfoot></tfoot>
                        </table>    
                    </div>
                </div> 

            </div>
            <HelmetProvider>
                <Helmet>
                    <script src="./js/checkPermissions.js" type="text/javascript"></script>
                    <script src="./js/datatables/facebook.js" type="text/javascript"></script>
                </Helmet>
            </HelmetProvider>  
        </div>
    );
}

export default Facebook;
import "../css/login.css";

import React, {useState, useEffect} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Logo from "../img/logo.png";
import apiUrl from '../globals';

const Login = () => {  

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    function setCookie(cname, cvalue, minutes) {
        let date = new Date();
        date.setTime(date.getTime()+(minutes*60*1000));
        let expires = "expires="+date.toGMTString();
        document.cookie = cname + "=" + JSON.stringify(cvalue) + ";" + expires + ";path=/";
    }

    const doLogin = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(apiUrl+'/do-login', {
                                        method: "POST",
                                        body: JSON.stringify({"username": userName, "password": password}),
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Accept": "*/*",
                                            //"token" : apiToken
                                        } 
                                    });                   
            const json = await response.json();
            console.log(json);
            if(json != false){
                setCookie("logged", json, 86400000);
                window.location.href = "/home";
            }else{
                window.$('.modal').modal('hide');
                window.$('.toast').find('.toast-body').html('Dados inválidos, tente novamente.');
                window.$('.toast').toast('show');
            }
        }catch(error){
            console.log(error);
        }
    }

    const handleChangeUserName = (event) =>{
        setUserName(event.target.value);
        event.preventDefault();
    }

    const handleChangePassword = (event) =>{
        setPassword(event.target.value);
        event.preventDefault();
    }

    useEffect(() => {
        //getPermissions();
    }, []);

    return (
        <div className="container-fluid" id="containerLogin">
            <div className="row">
                <div className="col-md-5 d-none d-md-flex bg-image-login"></div>
                <div className="col-md-7 bg-dark">
                    <div className="login d-flex align-items-center py-5">
                        <div className="container">
                            <div className="row" id="loginForm">
                                <div className="col-lg-10 col-xl-7 mx-auto">
                                    <img src={Logo} id="logoLogin" />
                                    <br /><br /><br />
                                    <form>
                                        <div className="form-group mb-3">
                                            <input id="userNameLogin" type="email" placeholder="E-mail" onChange={handleChangeUserName} required autoFocus className="form-control" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <input id="passwordLogin" type="password" placeholder="Senha" onChange={handleChangePassword} required className="form-control" />
                                        </div> 
                                        <br />                                       
                                        <button type="submit" className="btn btn-outline-primary text-uppercase" id="doLogin" onClick={doLogin}>Entrar</button>
                                        <br />
                                        <div className="text-center d-flex justify-content-between mt-4">
                                            <p>Developed with 💙 by <a href="https://404digitalmedia.com/" target="_blank" className="font-italic text-muted">404 Digital</a></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HelmetProvider>
                <Helmet>
                    <script src="./js/login.js" type="text/javascript"></script>
                </Helmet>
            </HelmetProvider> 
        </div>

        
    );
}

export default Login;
import './App.css';
import './css/default.css';

import { Route, Routes } from 'react-router-dom';
import Home from './views/home';
import Users from './views/users';
import Verticals from './views/verticals';
import GoogleAds from './views/google-ads';
import Gam from './views/gam';
import Facebook from './views/facebook';
import Active from './views/active';
import Navbar from './components/navbar';
import VerticalAccounts from './views/verticals-accounts';
import Permissions from './views/permissions';
import Login from './views/login';
import KeyValues from './views/key-values';
import Reports from './views/relatorios';
import ReportsROI from './views/report-roi';
import ReportsROICampaign from './views/report-roi-campaign';
import ReportsActive from './views/report-active';
import ReportsROIFacebook from './views/report-roi-facebook';
import ReportsROICampaignFacebook from './views/report-roi-campaign-facebook';
import Account from './views/account';
import UTMSources from './views/utm-sources';
import VerticalSources from './views/verticals-sources';
import ReportsImpressions from './views/report-roi-impressions';
import ReportsROIPlacement from './views/report-roi-placement';
function App() {
  return (
    <main>
      <Navbar />
      <Routes>
          <Route element={<Login/>} path="/" exact />
          <Route element={<Home/>} path="/home" exact />
          <Route element={<Users/>} path="/usuarios" />
          <Route element={<Permissions/>} path="/permissions" />
          <Route element={<Verticals/>} path="/verticais" />
          <Route element={<VerticalAccounts/>} path="/verticais-contas/:id" />
          <Route element={<VerticalSources/>} path="/verticais-sources/:id" />
          <Route element={<GoogleAds/>} path="/google-ads" />
          <Route element={<KeyValues/>} path="/key-values" />
          <Route element={<UTMSources/>} path="/utm-sources" />
          <Route element={<Gam/>} path="/gam" />
          <Route element={<Facebook/>} path="/facebook" />
          <Route element={<Active/>} path="/active" />
          <Route element={<Reports/>} path="/relatorios" />          
          <Route element={<ReportsROI/>} path="/relatorios/roi" />
          <Route element={<ReportsROIFacebook/>} path="/relatorios/roi-facebook" />   
          <Route element={<ReportsROICampaign/>} path="/relatorios/roi-campaign" />
          <Route element={<ReportsROICampaignFacebook/>} path="/relatorios/roi-campaign-facebook" />
          <Route element={<ReportsActive/>} path="/relatorios/active" />
          <Route element={<ReportsImpressions/>} path="/relatorios/impressoes" />
          <Route element={<ReportsROIPlacement/>} path="/relatorios/placement" />
          <Route element={<Account/>} path="/account" />          
      </Routes>
    </main>        
  );
}

export default App;

import "../css/users.css";

import React, {useState, useEffect} from "react";
import Breadcrumb from "../components/breadcrumb";
import apiUrl from '../globals';

const Account = () => {  

    const [userID, setUserID] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');

    const getAccount = async (user_id) => {
        try {
            const response = await fetch(apiUrl+'/get-user-by-id', {
                                        method: "POST",
                                        body: JSON.stringify({"id": user_id}),
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Accept": "*/*",
                                            //"token" : apiToken
                                        } 
                                    });                   
            const json = await response.json();
            setUserID(json.id);
            setUserName(json.name);
            setUserEmail(json.email);
            //console.log(json);
        }catch(error){
            console.log(error);
        }
    }

    const updateUser = async (evt) => {

        let data = {"id": userID, "email": userEmail, "name": userName};

        if(userPassword !== ""){
            data = {"id": userID, "email": userEmail, "name": userName, "password": userPassword};
        }

        try {
            const response = await fetch(apiUrl+'/update-account', {
                                        method: "POST",
                                        body: JSON.stringify(data),
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Accept": "*/*",
                                            //"token" : apiToken
                                        } 
                                    });                   
            const json = await response.json();
            window.$('.modal').modal('hide');
            window.$('.toast').find('.toast-body').html('Dados atualizados com sucesso, redirecionando para a home...');
            window.$('.toast').toast('show');
            setTimeout(() => {
                window.location.href = '/home';
            }, "3000");
        }catch(error){
            console.log(error);
        }

        evt.preventDefault();
    }

    const changeHandlerUserName = (event) => {        
        setUserName(event.target.value);
        event.preventDefault();
    }

    const changeHandlerUserEmail = (event) => {        
        setUserEmail(event.target.value);
        event.preventDefault();
    }

    const changeHandlerUserPassword = (event) => {        
        setUserPassword(event.target.value);
        event.preventDefault();
    }

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    } 

    let breadcrumbItens = [
        {
            text: "Home",
            route: "/"
        },
        {
            text: "Minha conta",
            route: "#"
        }
    ];

    useEffect(() => {
        let cookie = getCookie('logged');
        if(typeof cookie !== "undefined"){
            cookie = JSON.parse(cookie);
            getAccount(cookie.id);
        }  
    }, []);

    return (
        <div className="container">
            <div className="defaultContainer">
               
                <div className="row">
                    <div className="col-12">
                        <h1 className="h1">Minha conta</h1>
                        <Breadcrumb links={breadcrumbItens} />
                    </div>
                </div>
                <br />
                
                <div className="row">
                    <div className="col-12">
                        <form>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" placeholder="Nome" name="name" id="nome" aria-label="Nome" defaultValue={userName} onChange={changeHandlerUserName} />
                                <label htmlFor="nome">Seu nome:</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="email" className="form-control" placeholder="E-mail" name="email" id="email" aria-label="E-mail" defaultValue={userEmail} onChange={changeHandlerUserEmail} />
                                <label htmlFor="email">Seu e-mail:</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="password" className="form-control" placeholder="Senha" name="password" id="password" aria-label="Senha" defaultValue={userPassword} onChange={changeHandlerUserPassword} />
                                <label htmlFor="password">Nova senha:</label>
                                <small><i>Caso não queira atualizar sua senha deixe o campo em branco.</i></small>
                            </div>
                            <br />
                            <button type="button" onClick={updateUser} id="updateUser" className="btn btn-outline-success"><span className="material-symbols-outlined">save</span>&nbsp;Atualizar</button>
                        </form>   
                    </div>
                </div> 

            </div>
        </div>
    );
}

export default Account;
import "../css/users.css";

import React from "react";
import Breadcrumb from "../components/breadcrumb";
import ModalForm from "../components/modalForm";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const VerticalAccounts = () => {  

    let breadcrumbItens = [
        {
            text: "Home",
            route: "/"
        },
        {
            text: "Verticais",
            route: "/verticais"
        },
        {
            text: "Contas Vertical",
            route: "#"
        }
    ];

    let inputs = [
            {
                type: "select",
                name: "platform",
                label: "Plataforma",
                isRequired: true,
                id: "loadingAccountByPlatform",
                value: "0",
                options:[   
                            {
                                text: "Selecione a plataforma",
                                value: "0"
                            },
                            {
                                text: "Facebook",
                                value: "facebook"
                            },
                            {
                                text: "Google Ads",
                                value: "google_ads"
                            },
                            {
                                text: "GAM",
                                value: "gam"
                            },
                            {
                                text: "Active",
                                value: "active"
                            }
                        ]
            },
    ];

    return (
        <div className="container">
            <ModalForm input={inputs} action="save-vertical-account" elemId="verticalAccountModal" isEdit={false} title="Conta Vertical" />

            <div className="defaultContainer">
               
                <div className="row">
                    <div className="col-12">
                        <h1 className="h1">Contas Vertical
                            <button type="button" className="btn btn-sm btn-add" data-bs-toggle="modal" data-bs-target="#verticalAccountModal">
                                <span className="material-symbols-outlined transition">add_box</span>
                            </button>
                        </h1>
                        <Breadcrumb links={breadcrumbItens} />
                    </div>
                </div>
                <br />
                
                <div className="row">
                    <div className="col-12">
                        <table id="dtVerticalsAccounts" className="dataTables cell-border table table-bordered table-striped" style={{width:"100%"}}>
                            <thead>
                                <tr className="thead-dark">
                                    <th>Conta</th>
                                    <th>Plataforma</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                            <tfoot></tfoot>
                        </table>    
                    </div>
                </div> 

            </div>
            <HelmetProvider>
                <Helmet>
                    {/* <script src="../js/checkPermissions.js" type="text/javascript"></script>     */}
                    <script src="../js/datatables/vertical_accounts.js" type="text/javascript"></script>
                </Helmet>
            </HelmetProvider> 
        </div>
    );
}

export default VerticalAccounts;
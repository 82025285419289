import "../css/home.css";

import React, {useState, useEffect} from "react";
import parseHtml from 'html-react-parser';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Chart from 'chart.js/auto'

import apiUrl from '../globals';
import ActiveLogo from "../img/active.png";

const getDateAndTime = (format_br) => {
    let currentdate = new Date(); 
    let date;

    if(typeof format_br == "undefined" || format_br == null || format_br == ""){
        date =  currentdate.getFullYear()+"-"
				+(currentdate.getMonth()+1).toString().padStart(2, "0")+"-" 
				+currentdate.getDate().toString().padStart(2, "0");    
    }else{
        date =  currentdate.getDate().toString().padStart(2, "0")+"/"
                +(currentdate.getMonth()+1).toString().padStart(2, "0")+"/" 
                +currentdate.getFullYear();    
    }

	let time = currentdate.getHours()+":"+currentdate.getMinutes()+":"+currentdate.getSeconds();
    return {"date":date, "time": time};
}

let currentdate = new Date(); 
let currentHours = ('0'+currentdate.getHours()).substr(-2);
let currentHoursMinus2 = currentHours-2;
let currentMinutes = currentdate.getMinutes();

if(currentHoursMinus2 < 10){
    currentHoursMinus2 = '0'+currentHoursMinus2;
}

if(currentMinutes < 10){
    currentMinutes = '0'+currentMinutes;
}

let timeNowVar = currentHours+":"+currentMinutes;
let timeAgoVar = currentHoursMinus2+":"+currentMinutes;

const ReportsActive = () => {

    const [acAccount, setAcAccount] = useState('');    
    const [acAccountLeads, setAcAccountLeads] = useState('');    
    const [actualDate, setActualDate] = useState(getDateAndTime());
    const [timeNow, setTimeNow] = useState(timeNowVar);
    const [timeAgo, setTimeAgo] = useState(timeAgoVar);
    const [startDateAC, setStartDateAC] = useState(actualDate.date);
    //const [endDateAC, setEndDateAC] = useState(actualDate.date);
    const [endDateAC, setEndDateAC] = useState(0);

    const getACData = async (id_account) => {

        //console.log('acAccount state '+acAccount);

        if(acAccount != ''){
            id_account = acAccount;
        }

        console.log('id_account state '+id_account);

        if(id_account !== null && id_account != ''){

            console.log('id_account is not null');

            let retorno = {};
            
            try {
                const response = await fetch(apiUrl+'/get-active-data', {
                                            method: "POST",
                                            body: JSON.stringify({"id": id_account, "start_date": startDateAC, "end_date": endDateAC}),
                                            headers: {
                                                "Content-Type": "application/json",
                                                "Accept": "*/*",
                                                //"token" : apiToken
                                            } 
                                        });                   
                retorno = await response.json();
                //console.log(retorno);           
                                                    
            }catch(error){
                console.error(error);
            }finally{

                const colorsArray = ['#37306B', '#66347F', '#9E4784', '#D27685', '#F15A59', '#D5CEA3'];

                let activeChartData = [];
                let activeChartData2 = [];

                let counter = 10;    
                if(retorno.length < 10){
                    counter = retorno.length;
                } 

                for (let i = 0; i < counter; i++) {                        
                    activeChartData.push({"name":retorno[i].campaign_name, "ctr":retorno[i].ctr, "ctor":retorno[i].ctor});
                    activeChartData2.push({"name":retorno[i].campaign_name, "abertura":retorno[i].abertura, "sended":retorno[i].sended});
                }           

                (async function() {

                    let chartStatus = Chart.getChart('activeChart');
                    if (chartStatus != undefined) {
                        chartStatus.destroy();
                    }
                
                    new Chart(
                    document.getElementById('activeChart'),
                    {
                        type: 'bar',
                        data: {
                        labels: activeChartData.map(row => row.name),
                        datasets: [
                            {
                            label: 'CTR por campanha',
                            data: activeChartData.map(row => row.ctr),
                              borderColor: "#66347F",
                              backgroundColor: "#66347F"
                            },
                            {
                            label: 'CTOR por campanha',
                            data: activeChartData.map(row => row.ctor),
                              borderColor: "#D27685",
                              backgroundColor: "#D27685"
                            }
                        ]
                        },
                        options: {
                            scales: {
                            y: {
                                beginAtZero: true
                            }
                            }
                        },
                    }
                    );
                })();

                (async function() {

                    let chartStatus = Chart.getChart('activeChart2');
                    if (chartStatus != undefined) {
                        chartStatus.destroy();
                    }
                
                    new Chart(
                    document.getElementById('activeChart2'),
                    {
                        type: 'bar',
                        data: {
                        labels: activeChartData2.map(row => row.name),
                        datasets: [
                            {
                            label: 'Taxa de abertura por campanha',
                            data: activeChartData2.map(row => row.abertura),
                              borderColor: "#D5CEA3",
                              backgroundColor: "#D5CEA3"
                            },
                            // {
                            // label: 'Disparos por campanha',
                            // data: activeChartData2.map(row => row.sended),
                            //   borderColor: "#F15A59",
                            //   backgroundColor: "#F15A59"
                            // }
                        ]
                        },
                        options: {
                            scales: {
                            y: {
                                beginAtZero: true
                            }
                            }
                        },
                    }
                    );
                })();


                return retorno;
            }

        }else{
            console.log('id_account is null');
        }
    
    }

    const getACDataLeads = async (id_account) => {

        //console.log('acAccountLeads state '+acAccountLeads);

        if(acAccountLeads != ''){
            id_account = acAccountLeads;
        }

        console.log('id_account state '+id_account);

        if(id_account !== null && id_account != ''){
            //console.log('id_account is not null');

            let retorno = {};
            
            try {
                const response = await fetch(apiUrl+'/get-active-leads', {
                                            method: "POST",
                                            body: JSON.stringify({"id": id_account, "start_date": startDateAC, "end_date": endDateAC}),
                                            headers: {
                                                "Content-Type": "application/json",
                                                "Accept": "*/*",
                                                //"token" : apiToken
                                            } 
                                        });                   
                retorno = await response.json();
                console.log(retorno);             
            }catch(error){
                console.error(error);
            }finally{

                const colorsArray = ['#37306B', '#66347F', '#9E4784', '#D27685', '#F15A59', '#D5CEA3'];

                let activeChartDataLeads = [];

                //for (let i = 0; i < 10; i++) {              
                for (let i = 0; i < retorno.length; i++) {              
                    activeChartDataLeads.push({"name":retorno[i].account_name, "total":retorno[i].total, "date":retorno[i].date});
                    //console.log(retorno[i].account_name);
                }       
                //console.log(activeChartDataLeads);

                (async function() {

                    let chartStatus = Chart.getChart('activeChartLeads');
                    if (chartStatus != undefined) {
                        chartStatus.destroy();
                    }
                
                    new Chart(
                    document.getElementById('activeChartLeads'),
                    {
                        type: 'bar',
                        data: {
                        labels: activeChartDataLeads.map(row => row.date),
                        datasets: [
                            {
                            label: 'Leads ativos',
                            data: activeChartDataLeads.map(row => row.total),
                            //   borderColor: colorsArray,
                            //   backgroundColor: colorsArray
                            }
                        ]
                        },
                        options: {
                            scales: {
                            y: {
                                beginAtZero: true
                            }
                            }
                        },
                    }
                    );
                })();
                return retorno;
            }

        }else{
            console.log('id_account is null');
        }
    
    }

    const clickHandlerAc = (event) => {
        getACData(acAccount);
    }

    const clickHandlerAcLeads = (event) => {
        //getACDataLeads(acAccount);
    }

    const changeHandlerAc = (event) =>{
        //console.log(event.target.value);
        getACData(event.target.value);
        setAcAccount(event.target.value);
        event.preventDefault();
    }

    const changeHandlerAcLeads = (event) =>{
        //console.log(event.target.value);
        getACDataLeads(event.target.value);
        setAcAccountLeads(event.target.value);
        event.preventDefault();
    }

    const changeHandlerStartDateAC = (event) => {        
        //console.log("changeHandlerStartDateAC "+event.target.value);
        setStartDateAC(event.target.value);
        event.preventDefault();
    }

    const changeHandlerEndDateAC = (event) => {     
        //console.log("changeHandlerEndDateAC "+event.target.value);   
        setEndDateAC(event.target.value);
        event.preventDefault();
    }
    
    useEffect(()=>{
    }, []);

    let cardTitleActive = parseHtml('<img class="cardLogoTitle" src="'+ActiveLogo+'"/>&nbsp;<h5 class="card-title">Active Campaign</h5>');                       

    return (
        <div className="container-fluid">
            <div id="homeContainer">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="card border-secondary mb-3">
                                    <div className="card-header d-flex justify-content-start">
                                        {cardTitleActive}
                                        <div className="tableLoader" id="googleAdsLoader">
                                            <svg className="spinner" width="32px" height="32px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="card-body">

                                        <h4 className="subtitle">Campanhas</h4>
                                        <br />
                                        
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="mb-3">
                                                    <label htmlFor="filter_vertical_ac" className="form-label">Vertical</label>
                                                    <select id="filter_vertical_ac" name="filter_vertical" className="selectInput form-control">
                                                        <option value="0">Selecione a vertical</option>     
                                                    </select>    
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="mb-3">
                                                    <label htmlFor="filter_account_ac" className="form-label">Conta</label>
                                                    <select id="filter_account_ac" name="filter_account" className="selectInput form-control" onChange={changeHandlerAc}>
                                                        <option value="0">Selecione a vertical primeiro</option>     
                                                    </select>
                                                </div>    
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:"30px"}}>
                                            <div className="col">
                                                <h6>Exibindo dados de: <strong id="filterTextAC" className="text-success"></strong></h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-10">
                                                <div className="mb-3">
                                                    <label htmlFor="start_date_ac" className="form-label">Data</label>
                                                    <input name="start_date_ac" className="form-control" id="start_date_ac" type="date" onChange={changeHandlerEndDateAC} defaultValue={actualDate.date} />
                                                </div>    
                                            </div> */}
                                            <div className="col-5">
                                                <div className="mb-3">
                                                    <label htmlFor="start_date_ac" className="form-label">Data início</label>
                                                    <input name="start_date_ac" className="form-control" id="start_date_ac" type="date" onChange={changeHandlerStartDateAC} defaultValue={actualDate.date} />
                                                </div>    
                                            </div>
                                            <div className="col-5">
                                                <div className="mb-3">
                                                    <label htmlFor="end_date_ac" className="form-label">Data fim</label>
                                                    <input name="end_date_ac" className="form-control" id="end_date_ac" type="date" onChange={changeHandlerEndDateAC} defaultValue={actualDate.date} />
                                                </div>    
                                            </div>
                                            <div className="col-2">
                                                <div className="mb-3">
                                                    <button type="button" id="searchActive" className="btn btn-outline-primary" onClick={clickHandlerAc}>
                                                        <span className="material-symbols-outlined">filter_alt</span>&nbsp;Filtrar
                                                    </button>
                                                </div>    
                                            </div>
                                        </div>

                                        <br /><hr /><br />

                                        <div className="row">
                                            <div className="col-12">
                                                <table id="dtACHome" className="dataTables cell-border table table-bordered table-striped" style={{width:"100%"}}>
                                                    <thead>
                                                        <tr className="thead-dark">
                                                            <th>Conta</th>
                                                            <th>ID Campanha</th>
                                                            <th>Campanha</th>
                                                            <th>Disparos</th>
                                                            <th>Abertura</th>                                                    
                                                            <th>CTOR</th>
                                                            <th>CTR</th>
                                                            <th>Data/Hora leitura</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                    <tfoot></tfoot>
                                                </table>  
                                                
                                                <br /><br />

                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className="card mb-3 reportCard">
                                                            <div className="card-header"><span className="material-symbols-outlined">send</span>&nbsp;<label>Total de disparos</label></div>
                                                            <div className="card-body">
                                                                <h5 className="card-title activeTotals" id="totalSended">0</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="card mb-3 reportCard">
                                                            <div className="card-header"><span className="material-symbols-outlined">mark_email_read</span>&nbsp;<label>Média de aberturas</label></div>
                                                            <div className="card-body">
                                                                <h5 className="card-title activeTotals" id="totalTaxaAbertura">0%</h5>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div className="col-3">
                                                        <div className="card mb-3 reportCard">
                                                            <div className="card-header"><span className="material-symbols-outlined">drag_click</span>&nbsp;<label>Média de CTOR</label></div>
                                                            <div className="card-body">
                                                                <h5 className="card-title activeTotals" id="totalCTOR">0%</h5>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div className="col-3">
                                                        <div className="card mb-3 reportCard">
                                                            <div className="card-header"><span className="material-symbols-outlined">ads_click</span>&nbsp;<label>Média de CTR</label></div>
                                                            <div className="card-body">
                                                                <h5 className="card-title activeTotals" id="totalCTR">0%</h5>
                                                            </div>
                                                        </div>
                                                    </div>    
                                                </div>
                                            </div>
                                        </div>

                                        <br /><br />

                                        <div className="row">
                                            <div className="col-6 chartContainer">
                                                <canvas className="chartsHome" id="activeChart"></canvas>
                                            </div>
                                            <div className="col-6 chartContainer">
                                                <canvas className="chartsHome" id="activeChart2"></canvas>
                                            </div>
                                        </div>

                                        <br /><hr /><br />

                                        <h4 className="subtitle">Leads ativos</h4>
                                        <br />

                                        <div className="row">
                                            <div className="col-6">
                                                <div className="mb-3">
                                                    <label htmlFor="filter_vertical_ac_leads" className="form-label">Vertical</label>
                                                    <select id="filter_vertical_ac_leads" name="filter_vertical_ac_leads" className="selectInput form-control">
                                                        <option value="0">Selecione a vertical</option>     
                                                    </select>    
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="mb-3">
                                                    <label htmlFor="filter_account_ac_leads" className="form-label">Conta</label>
                                                    <select id="filter_account_ac_leads" name="filter_account_leads" className="selectInput form-control" onChange={changeHandlerAcLeads}>
                                                        <option value="0">Selecione a vertical primeiro</option>     
                                                    </select>
                                                </div>    
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop:"30px"}}>
                                            <div className="col">
                                                <h6>Exibindo dados de: <strong id="filterTextACLeads" className="text-success"></strong></h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-10">
                                                <div className="mb-3">
                                                    <label htmlFor="start_date_ac_leads" className="form-label">Data</label>
                                                    <input name="start_date_ac_leads" className="form-control" id="start_date_ac_leads" type="date" defaultValue={actualDate.date} />
                                                </div>    
                                            </div> */}
                                            <div className="col-5">
                                                <div className="mb-3">
                                                    <label htmlFor="start_date_ac_leads" className="form-label">Data início</label>
                                                    <input name="start_date_ac_leads" className="form-control" id="start_date_ac_leads" type="date" defaultValue={actualDate.date} />
                                                </div>    
                                            </div>
                                            <div className="col-5">
                                                <div className="mb-3">
                                                    <label htmlFor="end_date_ac_leads" className="form-label">Data fim</label>
                                                    <input name="end_date_ac_leads" className="form-control" id="end_date_ac_leads" type="date" defaultValue={actualDate.date} />
                                                </div>    
                                            </div>
                                            <div className="col-2">
                                                <div className="mb-3">
                                                    <button type="button" id="searchActiveLeads" className="btn btn-outline-primary" onClick={clickHandlerAcLeads}>
                                                        <span className="material-symbols-outlined">filter_alt</span>&nbsp;Filtrar
                                                    </button>
                                                </div>    
                                            </div>
                                        </div>

                                        <br /><hr /><br />

                                        <div className="row">
                                            <div className="col-12">
                                                <table id="dtACHomeLeads" className="dataTables cell-border table table-bordered table-striped" style={{width:"100%"}}>
                                                    <thead>
                                                        <tr className="thead-dark">
                                                            <th>Conta</th>
                                                            <th>Data amostragem</th>
                                                            <th>Leads ativos</th>
                                                            <th>Leads novos</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                    <tfoot></tfoot>
                                                </table>    
                                            </div>
                                            {/* <div className="col-3 chartContainer">
                                                <canvas className="chartsHome" id="activeChartLeads"></canvas>
                                            </div> */}
                                        </div>

                                    </div>
                                </div>
                            </div>           
                        </div> 
                    </div>                
                </div>
            </div>
            <HelmetProvider>
                <Helmet>
                    <script src="../js/datatables/reports/active.js" type="text/javascript"></script>
                    <script src="../js/datatables/reports/active_leads.js" type="text/javascript"></script>
                </Helmet>
            </HelmetProvider>  
        </div>
    );
}

export default ReportsActive;
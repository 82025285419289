import React from "react";
import {Link} from 'react-router-dom';

const MenuButton = ({route, id, text, icon, onClick}) => {
    return (
        <li className="nav-item">
            <Link to={route} onClick={onClick} id={id} className="menuNavItem transition"><span className="material-symbols-outlined transition">{icon}</span>&nbsp;{text}</Link>
        </li>
    );
}

export default MenuButton;
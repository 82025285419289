import "../css/users.css";

import React from "react";
import Breadcrumb from "../components/breadcrumb";
import ModalForm from "../components/modalForm";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const UTMSources = () => {  

    let breadcrumbItens = [
        {
            text: "Home",
            route: "/"
        },
        {
            text: "UTM Source",
            route: "#"
        }
    ];

    let inputs = [
            {              
                type: "text",
                name: "name",
                label: "Nome",
                isRequired: true
            },
            {              
                type: "number",
                name: "min_impression",
                label: "Mínimo de impressões",
                isRequired: false,
            }
    ];

    return (
        <div className="container">
            <ModalForm input={inputs} action="save-utm-source" elemId="UTMSourceModal" isEdit={false} title="UTM Source" />
            <ModalForm input={inputs} action="update-utm-source" elemId="editUTMSourceModal" isEdit={true} title="UTM Source" />

            <div className="defaultContainer">
               
                <div className="row">
                    <div className="col-12">
                        <h1 className="h1">UTM Sources
                            <button type="button" className="btn btn-sm btn-add" data-bs-toggle="modal" data-bs-target="#UTMSourceModal">
                                <span className="material-symbols-outlined transition">add_box</span>
                            </button>
                        </h1>
                        <Breadcrumb links={breadcrumbItens} />
                    </div>
                </div>
                <br />
                
                <div className="row">
                    <div className="col-12">
                        <table id="dtUTMSource" className="dataTables cell-border table table-bordered table-striped" style={{width:"100%"}}>
                            <thead>
                                <tr className="thead-dark">
                                    <th>Nome</th>
                                    <th>Mínimo de impressões</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                            <tfoot></tfoot>
                        </table>    
                    </div>
                </div> 

            </div>
            <HelmetProvider>
                <Helmet>
                    <script src="./js/checkPermissions.js" type="text/javascript"></script>
                    <script src="./js/datatables/utm_source.js" type="text/javascript"></script>
                </Helmet>
            </HelmetProvider> 
        </div>
    );
}

export default UTMSources;